<template>
  <div class="edit">
    <TopBar :type="2" :pageTitle="type==1?'昵称':'真实姓名'" />
    <div class="save-btn" @click="saveBtn">保存</div>
    <div class="content">
      <div class="input-box">
        <input type="text" class="input" v-model="name" ref='input' maxlength="8">
        <div class="clear" @click="name=''"></div>
      </div>
      <p class="tip-err">{{tipErr}}</p>
      <p class="tip">请输入2-8位汉字，英文或者数字，且昵称不能为纯数字</p>
      <p class="name">{{type==1?userInfo.nickname:userInfo.name}} </p>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'Edit',
  data(){
    return {
      type: '',
      name: '',
      tipErr: ''
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.$refs.input.focus(); //input自动获取光标
    this.type = this.$route.params.type;
    this.GetUserInfo();
  },
  methods: {
    ...mapMutations(['ShowTipModal', 'GetUserInfo']), //tip弹窗
    saveBtn(){
      if(this.name.length==0){
        this.tipErr = '你还没有填写文字';
        return;
      }else if(this.name.length<2){
        this.tipErr = '您最少要输入2个字';
        return;
      }else if(this.name.length>8){
        this.tipErr = '您最多只能输入8个字';
        return;
      }

      let data = {}

      if(this.type==1){
        data.nickname = this.name;
      }else{
        data.name = this.name;
      }

      this.$axios.post(`/v1/personal/updateInfo`, data, {useLog: true}).then(res => {
        if(res.code == 0){
          let _this = this;
          this.ShowTipModal({
            text: '修改成功',
            fun: function(){
              //_this.getUserInfo1();
              this.$router.go(-1);
            }
          })
        }
      })
    },
    //获取用户信息
    getUserInfo1(){
      this.$axios.post(`/v1/personal/userInfo`, {}, {useLog: true}).then(res => {
        if(res.code == 0){
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.GetUserInfo();
          this.showAvatar = this.userInfo.avatar;
        }
      })
    },
  },
  computed: {
    ...mapState(['tipModal', "userInfo"])
  },
}
</script>

<style lang="scss" scoped>
.edit{
  position: relative;
}
.save-btn{
  width: 88px;
  height: 88px;
  line-height: 88px;
  text-align: center;
  font-size: 32px;
  color: #254ED4;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 999;
}
.content{
  padding: 16px 28px;
}
.input-box{
  width: 100%;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input{
    width: 600px;
    padding: 28px 0;
    font-size: 28px;
    color: #333;
  }
  .clear{
    width: 34px;
    height: 34px;
    background: url(~@/assets/close-grey-ico.png) center center no-repeat;
    background-size: auto 100%;
  }
}
.tip{
  padding-top: 28px;
  font-size: 24px;
  color: #999;
}
.name{
  padding-top: 28px;
  font-size: 24px;
  color: #666;
}
.tip-err{
  font-size: 28px;
  color: #CD1B14;
}
</style>
